import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

import styles from "../style/pages/about.module.scss";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const about = data.markdownRemark;

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className={styles.container}>
            <img className={styles.image} src={about.frontmatter.cover} />
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: about.html,
              }}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <h4>Want J. Scott George to Speak at your next event?</h4>
          <a href="mailto:scottdestiny@aol.com?subject=Speaking%20engagement%20">
            <button>CONTACT US</button>
          </a>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/content/about/" }) {
      html
      frontmatter {
        title
        cover
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
